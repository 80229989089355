import React, { useEffect, useRef } from 'react';
import { GlifoCentral } from './GlifoCentral'; 


function Carrossel ({ ftP, p1, p2, p3, p4, p5, p6, p7, tema, estilo }){
    const temaFade = {
        vermelho:'fdBranco', laranja:'fdBranco', amarelo:'fdBranco', verde:'fdBranco', ciano:'fdBranco',
    azul:'fdBranco', roxo:'fdBranco', rosa:'fdBranco', magenta:'fdBranco', branco:'fdBranco',
    
    goldVermelho: 'fdVermelho', goldVerde:'fdVerde', goldAzul:'fdAzul', goldRoxo:'fdRoxo', goldAmarelo:'fdAmarelo',
    silverVermelho: 'fdVermelho', silverVerde:'fdVerde', silverAzul:'fdAzul', silverRoxo:'fdRoxo', silverAmarelo:'fdAmarelo',
    vermelhoVerde:'fdVerde', laranjaCiano:'fdCiano', verdeVermelho:'fdVermelho', azulAmarelo:'fdAmarelo',
    }

    const bordaPerfil = {
        vermelho: 'bordaVermelho', laranja: 'bordaLaranja', amarelo: 'bordaAmarelo', verde: 'bordaVerde', ciano: 'bordaCiano',
        azul:'bordaAzul', roxo:'bordaRoxo', rosa:'bordaRosa', magenta:'bordaMagenta', branco: 'bordaBranco',

        goldVermelho: 'bordaAmarelo', goldVerde:'bordaAmarelo', goldAzul:'bordaAmarelo', goldRoxo:'bordaAmarelo', goldAmarelo:'bordaAmarelo',

        silverVermelho: 'bordaBranco', silverVerde:'bordaBranco', silverAzul:'bordaBranco', silverRoxo:'bordaBranco', silverAmarelo:'bordaBranco',
    
        vermelhoVerde:'bordaVermelho', laranjaCiano:'bordaLaranja', verdeVermelho:'bordaVerde', azulAmarelo:'bordaAzul',
    }
   
   

    return(<>
            {/* Carrossel indo para a direita */}
            <div className="bioline1">
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        
      </div>

      {/* Carrossel indo para a esquerda */}
      <div className="bioline2">
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        {p7 ? <div className="photo"><img id="ph7" src={p7} alt="" className="photo" /></div> : null}
        {p6 ? <div className="photo"><img id="ph6" src={p6} alt="" className="photo" /></div> : null}
        {p5 ? <div className="photo"><img id="ph5" src={p5} alt="" className="photo" /></div> : null}
        {p4 ? <div className="photo"><img id="ph4" src={p4} alt="" className="photo" /></div> : null}
        {p3 ? <div className="photo"><img id="ph3" src={p3} alt="" className="photo" /></div> : null}
        {p2 ? <div className="photo"><img id="ph2" src={p2} alt="" className="photo" /></div> : null}
        {p1 ? <div className="photo"><img id="ph1" src={p1} alt="" className="photo" /></div> : null}
        
      </div>
        
             <div className={tema ? temaFade[tema] || '' : ''}></div>

             {/*<div className='bordaPP' >
                        <img className='glifocentral'
                        src={GlifoCentral(estilo, tema)}
                        style={{ display: estilo === 'estiloN' ? 'none' : 'flex' }}/>
                    </div>*/}


            <div className='perfilB '>
                    
                    <div className="perfilP">
                        <img id="ph1" src={ftP || 'https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2FAnyConv.com__fotonull.webp?alt=media&token=a2f03184-202c-4db9-a1bd-a62c3236b71d'} alt="" className="perfilP"/>
                       
                        <div className="perfilPReflex">
                    <img style={{width:'100%', height:'100%'}} src='https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Freflex2.svg?alt=media&token=9477b811-435c-464d-a6bc-d46cdc64f035&_gl=1*1ryryd4*_ga*MTY1MTk0MzM3Ni4xNjg5ODc4MDYx*_ga_CW55HF8NVT*MTY5ODAyMDg3Ny4yODQuMS4xNjk4MDIxODA2LjQ5LjAuMA..' 
                        alt="" />
                    </div>
                    </div>
                    
                    
            </div>

            <div className={`perfilB ${tema ? bordaPerfil[tema] || '' : ''}`}>
                    
            </div>
 </>)
}

export default Carrossel;