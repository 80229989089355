import React, { useState, useEffect, useRef } from "react";

import { getFirestore, getDocs, query, where, collection } from "firebase/firestore";
import firebase from '../../services/firebaseConfig';
import { FaCircleCheck } from "react-icons/fa6";

function Perso1({ onNext, onBack, formData, setFormData, nome, perfil, initialCroppedPhotoURL }) {

  const fontPage = {
    roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif', alegreya: 'fontAlegreya', suse: 'fontSuse', rajdhani: 'fontRajdhani',
    playpen: 'fontPlaypen', josefin:'fontJosefin', playfair:'fontPlayfair', nunito:'fontNunito'
  };

  const colorPage = {
    vermelho:'pagePadrao bVer', laranja:'pagePadrao bLan', amarelo:'pagePadrao bAma', verde:'pagePadrao bVerd', ciano:'pagePadrao bCia',
    azul:'pagePadrao bAzu', roxo:'pagePadrao bRox', rosa:'pagePadrao bRos', magenta:'pagePadrao bMag', branco:'pagePadrao bBra',

    goldVermelho: 'pageVer bAma', goldVerde:'pageVerd bAma', goldAzul:'pageAzu bAma', goldRoxo:'pageRox bAma', goldAmarelo:'pageAma bAma',

    silverVermelho: 'pageVer bBra', silverVerde:'pageVerd bBra', silverAzul:'pageAzu bBra', silverRoxo:'pageRox bBra', silverAmarelo:'pageAma bBra',

    vermelhoVerde:'pageVerd bVer', laranjaCiano:'pageCia bLan', verdeVermelho:'pageVer bVerd', azulAmarelo:'pageAma bAzu',
    
    
  };

  const colorBorder = {
    vermelho:'bVer', laranja:'bLan', amarelo:'bAma', verde:'bVerd', ciano:'bCia',
    azul:'bAzu', roxo:'bRox', rosa:'bRos', magenta:'bMag', branco:'bBra',

    goldVermelho: 'bAma', goldVerde:'bAma', goldAzul:'bAma', goldRoxo:'bAma', goldAmarelo:'bAma',

    silverVermelho: 'bBra', silverVerde:'bBra', silverAzul:'bBra', silverRoxo:'bBra', silverAmarelo:'bBra',

    vermelhoVerde:'bVer', laranjaCiano:'bLan', verdeVermelho:'bVerd', azulAmarelo:'bAzu',
    
    
  };


  
  const handleColorSelect = (colorId) => {
    setFormData({ ...formData, cor: colorId });
  };

  const handleFonteSelect = (font) => {
    setFormData({ ...formData, fonte: font });
  };

  useEffect(() => {
    if (!formData.estilo) { // Verifica se o estilo já está definido
      setFormData(prevState => ({
        ...prevState,
        cor: "vermelho",
        fonte: "roboto" // Define o valor inicial como "estiloN"
      }));
    }
  }, []);

    return(
        <div className='prP'>
          <img style={{ width: "150px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>

<span style={{marginTop:'24px' ,fontSize:'24px', fontWeight:'700', color:"#fff"}}>Passo 3 <span style={{ fontWeight:'100', color: '#dffc01'}}>de 5</span></span>
  <span style={{ color:'#dffc01',marginBottom:'8px',fontSize:'14px'}}>Definir a cor do seu catálogo</span>
  <hr style={{ background:'#dffc01',marginBottom:'24px',}}/>

          <div className="pr--prevCont bgly">
            <div className="pr--prevCont--title">Preview</div>
            <div className={`previewPerfil ${formData.fonte ? fontPage[formData.fonte] || '' : ''} ${formData.cor ? colorPage[formData.cor] || '' : ''}`}>
                <div className='previewPerfilb'></div>
                  <div className={`prepreviewPerfil-borda ${formData.cor ? colorBorder[formData.cor] || '' : ''}`}>
                    <img className="prepreviewPerfil-foto" src={initialCroppedPhotoURL} alt="" background="#fff"/>
                  </div>
                  {formData.nome}
                </div>
                <p className='p--Info'>Você poderá mudar a personalização depois</p>

          </div>
            

<div className='pr--labelsPerso'>
                
<div className="bgly2" style={{ width:'-webkit-fill-available', padding:'8px', borderRadius:'16px'}}>
                <label02>Escolha uma cor:</label02>
                <div className="cores">
                  
                <div className="cores--estilo">
                Padrão
                  <div className="cores--cores">
                    
                  <div id="vermelho"  onClick={() => handleColorSelect("vermelho")} style={{border: '2px solid #FF0000' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "vermelho" && <FaCircleCheck />}</div>
                  <div id="laranja"  onClick={() => handleColorSelect("laranja")} style={{border: '2px solid #FF8000' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "laranja" && <FaCircleCheck />}</div>
                  <div id="amarelo"  onClick={() => handleColorSelect("amarelo")} style={{border: '2px solid #FFD400' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "amarelo" && <FaCircleCheck />}</div>
                  <div id="verde"  onClick={() => handleColorSelect("verde")} style={{border: '2px solid #00FF00' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "verde" && <FaCircleCheck />}</div>
                  <div id="ciano"  onClick={() => handleColorSelect("ciano")} style={{border: '2px solid #00FFFF' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "ciano" && <FaCircleCheck />}</div>
                  <div id="azul"  onClick={() => handleColorSelect("azul")} style={{border: '2px solid #0080FF' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "azul" && <FaCircleCheck />}</div>
                  <div id="roxo"  onClick={() => handleColorSelect("roxo")} style={{border: '2px solid #AA00FF' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "roxo" && <FaCircleCheck />}</div>
                  <div id="rosa"  onClick={() => handleColorSelect("rosa")} style={{border: '2px solid #FF00D4' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "rosa" && <FaCircleCheck />}</div>
                  <div id="magenta"  onClick={() => handleColorSelect("magenta")} style={{border: '2px solid #FF0055' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "magenta" && <FaCircleCheck />}</div>
                  <div id="branco"  onClick={() => handleColorSelect("branco")} style={{border: '2px solid #CCCCCC' ,background:'#191919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "branco" && <FaCircleCheck />}</div>
                  
                  </div>

                </div>

                <div className="cores--estilo">
                Gold
                  <div className="cores--cores">
                    
                    <div id="goldVermelho" onClick={() => handleColorSelect("goldVermelho" )} style={{border: '2px solid #FFD400' ,background:'#190000',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "goldVermelho" && <FaCircleCheck />}</div>
                    <div id="goldVerde" onClick={() => handleColorSelect("goldVerde")} style={{border: '2px solid #FFD400' ,background:'#001900',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "goldVerde" && <FaCircleCheck />}</div>
                    <div id="goldAzul" onClick={() => handleColorSelect("goldAzul")} style={{border: '2px solid #FFD400' ,background:'#000D19',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "goldAzul" && <FaCircleCheck />}</div>
                    <div id="goldRoxo" onClick={() => handleColorSelect("goldRoxo")} style={{border: '2px solid #FFD400' ,background:'#110019',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "goldRoxo" && <FaCircleCheck />}</div>
                    <div id="goldAmarelo" onClick={() => handleColorSelect("goldAmarelo")} style={{border: '2px solid #FFD400' ,background:'#191500',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "goldAmarelo" && <FaCircleCheck />}</div>
                   
                    

                  </div>

                </div>

                <div className="cores--estilo">
                Silver
                  <div className="cores--cores">
                    
                    <div id="silverVermelho" onClick={() => handleColorSelect("silverVermelho")} style={{border: '2px solid #CCCCCC' ,background:'#190000',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "silverVermelho" && <FaCircleCheck />}</div>
                    <div id="silverVerde" onClick={() => handleColorSelect("silverVerde")} style={{border: '2px solid #CCCCCC' ,background:'#001900',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "silverVerde" && <FaCircleCheck />}</div>
                    <div id="silverAzul" onClick={() => handleColorSelect("silverAzul")} style={{border: '2px solid #CCCCCC' ,background:'#000D19',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "silverAzul" && <FaCircleCheck />}</div>
                    <div id="silverRoxo" onClick={() => handleColorSelect("silverRoxo")} style={{border: '2px solid #CCCCCC' ,background:'#110019',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "silverRoxo" && <FaCircleCheck />}</div>
                    {/*<div id="silverAmarelo" onClick={() => handleColorSelect("silverAmarelo")} style={{border: '2px solid #CCCCCC' ,background:'#191500',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "silverAmarelo" && <FaCircleCheck />}</div>*/}
                   
                    

                  </div>

                </div>

                <div className="cores--estilo">
                Complementares
                  <div className="cores--cores">
                    
                    <div id="vermelhoVerde" onClick={() => handleColorSelect("vermelhoVerde")} style={{border: '2px solid #FF0000' ,background:'#001900',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "vermelhoVerde" && <FaCircleCheck />}</div>
                    <div id="laranjaCiano" onClick={() => handleColorSelect("laranjaCiano")} style={{border: '2px solid #FF8000' ,background:'#001919',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "laranjaCiano" && <FaCircleCheck />}</div>
                    <div id="verdeVermelho" onClick={() => handleColorSelect("verdeVermelho")} style={{border: '2px solid #00FF00' ,background:'#190000',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "verdeVermelho" && <FaCircleCheck />}</div>
                    {/*<div id="azulAmarelo" onClick={() => handleColorSelect("azulAmarelo")} style={{border: '2px solid #0079F2' ,background:'#191500',color:'#FFf', borderRadius:'16px'}} className="Color">{formData.cor === "azulAmarelo" && <FaCircleCheck />}</div>*/}
                   
                    

                  </div>

                </div>

                

                </div> 

                </div>


                {/*<div className="bgly2" style={{ width:'-webkit-fill-available', padding:'16px', borderRadius:'16px'}}>
                <label>Escolha uma fonte:</label>
                <div className="fonte">
                  
                    <div onClick={() => handleFonteSelect("roboto")} className={`fonte--bt ${formData.fonte === "roboto" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto"}}>roboto</div>
                    <div onClick={() => handleFonteSelect("slab")} className={`fonte--bt ${formData.fonte === "slab" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto slab"}}>slab</div>
                    <div onClick={() => handleFonteSelect("serif")} className={`fonte--bt ${formData.fonte === "serif" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>serif</div>
                    <div onClick={() => handleFonteSelect("alegreya")} className={`fonte--bt ${formData.fonte === "alegreya" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>alegreya</div>
                    <div onClick={() => handleFonteSelect("rajdhani")} className={`fonte--bt ${formData.fonte === "rajdhani" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>rajdhani</div>
                    <div onClick={() => handleFonteSelect("playpen")} className={`fonte--bt ${formData.fonte === "playpen" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>playpen</div>
                    <div onClick={() => handleFonteSelect("josefin")} className={`fonte--bt ${formData.fonte === "josefin" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>josefin</div>
                    <div onClick={() => handleFonteSelect("playfair")} className={`fonte--bt ${formData.fonte === "playfair" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>playfair</div>
                    <div onClick={() => handleFonteSelect("nunito")} className={`fonte--bt ${formData.fonte === "nunito" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>nunito</div>
                    <div onClick={() => handleFonteSelect("suse")} className={`fonte--bt ${formData.fonte === "suse" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>suse</div>
                  
                </div>
                </div>*/}


                
                


                
                

                 


                    

                    

                
            </div>

            <div className='pr--buttons'>
                <button className='pr--back' onClick={onBack}>Voltar</button>
                <button className='pr--next' onClick={onNext} >Avançar</button>

            </div>
            

        </div>
    )
}


export default Perso1;