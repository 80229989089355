import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import React, { useEffect, useState } from "react";
import { updateDoc, getDocs, query, where, getFirestore, collection, onSnapshot, doc } from 'firebase/firestore';
import firebaseApp from '../../services/firebaseConfig'; // Importe sua instância do Firebase aqui
import SettingsTattoo from "./Items/SettingsTattoo";

function Tattoo({ eID, scID, id, nomeT, stDesc, descr,
   capa, img1, img2, img3, img4,
    stPreco, preco, stTamanho, tam, index,  }) {
   

    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    const handleArrowDownClick = async () => {
        try {
          
          console.log('Valor de scID:', scID);
          console.log('Valor de index:', index);
      
          if (!scID || index === undefined || index === null) {
            console.error('scID ou index não estão definidos ou são inválidos.');
            return;
          }
      
          const db = getFirestore(firebaseApp);
      
          // Consulta a coleção 'tattoos' com base na seção e ordem atual
          const currentTattooQuery = query(
            collection(db, 'tattoos'),
            where('SecaoID', '==', scID),
            where('ordem', '==', index)
          );
      
          const currentTattooSnapshot = await getDocs(currentTattooQuery);
      
          if (!currentTattooSnapshot.empty) {
            // Consulta a próxima tatuagem na ordem
            const nextTattooQuery = query(
              collection(db, 'tattoos'),
              where('SecaoID', '==', scID),
              where('ordem', '==', index + 1)
            );
      
            const nextTattooSnapshot = await getDocs(nextTattooQuery);
      
            if (!nextTattooSnapshot.empty) {
              const currentTattooDoc = currentTattooSnapshot.docs[0];
              const nextTattooDoc = nextTattooSnapshot.docs[0];
      
              // Obtenha os dados das tatuagens atuais
              const currentTattooData = currentTattooDoc.data();
              const nextTattooData = nextTattooDoc.data();
      
              // Atualize a ordem da tatuagem atual para a próxima ordem
              await updateDoc(currentTattooDoc.ref, { ordem: index + 1 });
      
              // Atualize a ordem da próxima tatuagem para a ordem atual
              await updateDoc(nextTattooDoc.ref, { ordem: index });
      
              console.log('Nome da Tatuagem Atual:', currentTattooData.nomeTattoo);
              console.log('Nome da Próxima Tatuagem:', nextTattooData.nomeTattoo);
            } else {
              console.log('Nenhuma próxima tatuagem encontrada');
            }
          } else {
            console.log('Nenhuma tatuagem encontrada para a ordem atual');
          }
        } catch (error) {
          console.error('Erro ao buscar documento:', error);
        }
      };
    

      const handleArrowUpClick = async () => {
        try {
          console.log('Valor de scID:', scID);
          console.log('Valor de index:', index);
      
          if (!scID || index === undefined || index === null) {
            console.error('scID ou index não estão definidos ou são inválidos.');
            return;
          }
      
          const db = getFirestore(firebaseApp);
      
          // Consulta a coleção 'tattoos' com base na seção e ordem atual
          const currentTattooQuery = query(
            collection(db, 'tattoos'),
            where('SecaoID', '==', scID),
            where('ordem', '==', index)
          );
      
          const currentTattooSnapshot = await getDocs(currentTattooQuery);
      
          if (!currentTattooSnapshot.empty) {
            // Consulta a próxima tatuagem na ordem
            const nextTattooQuery = query(
              collection(db, 'tattoos'),
              where('SecaoID', '==', scID),
              where('ordem', '==', index - 1)
            );
      
            const nextTattooSnapshot = await getDocs(nextTattooQuery);
      
            if (!nextTattooSnapshot.empty) {
              const currentTattooDoc = currentTattooSnapshot.docs[0];
              const nextTattooDoc = nextTattooSnapshot.docs[0];
      
              // Obtenha os dados das tatuagens atuais
              const currentTattooData = currentTattooDoc.data();
              const nextTattooData = nextTattooDoc.data();
      
              // Atualize a ordem da tatuagem atual para a próxima ordem
              await updateDoc(currentTattooDoc.ref, { ordem: index - 1 });
      
              // Atualize a ordem da próxima tatuagem para a ordem atual
              await updateDoc(nextTattooDoc.ref, { ordem: index });
      
              console.log('Nome da Tatuagem Atual:', currentTattooData.nomeTattoo);
              console.log('Nome da Próxima Tatuagem:', nextTattooData.nomeTattoo);
            } else {
              console.log('Nenhuma próxima tatuagem encontrada');
            }
          } else {
            console.log('Nenhuma tatuagem encontrada para a ordem atual');
          }
        } catch (error) {
          console.error('Erro ao buscar documento:', error);
        }
      };

      const [quantidadeTattoos, setQuantidadeTattoos] = useState(0);

      useEffect(() => {
        const db = getFirestore(firebaseApp);
        const tattoosQuery = query(
          collection(db, 'tattoos'),
          where('SecaoID', '==', scID)
        );
    
        const unsubscribe = onSnapshot(tattoosQuery, (snapshot) => {
          setQuantidadeTattoos(snapshot.size); // Atualiza a quantidade de tatuagens
        });
    
        return () => {
          unsubscribe();
        }
      }, [scID]);

  return (
    <div className="tattoo">
      <div className="tParte2">
        
        
        <AiOutlineArrowUp 
        className="iconNT" onClick={handleArrowUpClick}
        style={{ visibility: index === 0 ? 'hidden' : 'visible' }} />
        <p className="ordem">#</p>
        <AiOutlineArrowDown
         className="iconNT" onClick={handleArrowDownClick}
         style={{ visibility: index === quantidadeTattoos - 1 ? 'hidden' : 'visible' }}
         />
      </div>

      <div className="tParte1">
        <div className="sNomeT">
          <img src={capa} alt="" className="capaTattoo" />
          <p className="nomeTattoo">{nomeT}</p>
        </div>
        <div className="sSettings">
        <SlOptionsVertical
          className={`iconSTA ${isSettingsVisible ? 'none' : ''}`}
          onClick={() => setIsSettingsVisible(true)}
        />
        <SlOptionsVertical
          className={`iconSTD ${!isSettingsVisible ? 'none' : ''}`}
          onClick={() => setIsSettingsVisible(false)}
        />
        </div>
      </div>
      
      {isSettingsVisible && <SettingsTattoo eID={eID} scID={scID}
      tattooID={id} nomeT={nomeT} stDesc={stDesc} descr={descr} 
      img1={img1} img2={img2} img3={img3} img4={img4} 
      stTamanho={stTamanho} tam={tam} stPreco={stPreco} preco={preco} setIsSettingsVisible={()=>setIsSettingsVisible(false)}
      />}
    </div>
  );
}

export default Tattoo;
