import React, { useState, useEffect } from "react";
import {   updateDoc,
  getDocs,
  query,
  where,
  getFirestore,
  collection,
  doc,
  onSnapshot } from 'firebase/firestore';
import { BiSolidChevronDown, BiSolidChevronUp } from "react-icons/bi";
import "./../../pages/membros/AMembros.css";
import { FaRankingStar } from "react-icons/fa6";
import ConteudoTop from "./ConteudoTop";

function Top({ eID, ID_Sessao, nomeSessao, online, ordem }) {
  console.log("Este é o ID do estudio: ", eID)
  const [isOnline, setIsOnline] = useState(online);
  const [isConteudoVisivel, setIsConteudoVisivel] = useState(false);
  const [topOn, setTopOn] = useState(null);
  const [numDocs, setNumDocs] = useState(null);

  const [stateTop1, setStateTop1] = useState(null);
  const [stateTop2, setStateTop2] = useState(null);
  const [stateTop3, setStateTop3] = useState(null);
  const [stateTop4, setStateTop4] = useState(null);
  const [stateTop5, setStateTop5] = useState(null);
  const [stateTop6, setStateTop6] = useState(null);
  const [stateTop7, setStateTop7] = useState(null);

  const toggleOnline = () => {
    setIsOnline(!isOnline);
    
  };

  const toggleConteudoVisivel = () => {
    setIsConteudoVisivel(!isConteudoVisivel);
  };

  const toggleTopOn = async () => {
    if (numDocs < 7) {
      alert("Defina o TOP7 completo para deixa-lo ONLINE");
      return;
    }
    const db = getFirestore();
    const docRef = doc(db, 'estudio', eID);
    await updateDoc(docRef, {
      topOn: !topOn
    });
  };

  useEffect(() => {
    const db = getFirestore();
    if (eID) {
      const docRef = doc(db, 'estudio', eID);
  
    // Escutando em tempo real
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setTopOn(doc.data().topOn);
      } else {
        console.log("Documento não encontrado!");
      }
    });
  
    // Limpa o listener quando o componente é desmontado
    return () => unsubscribe();
    }
    
  }, [eID]);

  useEffect(() => {
    if(eID !== null) {
      const db = getFirestore();
      const q = query(collection(db, 'tattoos'), where('EstudioID', '==', eID), where('top', '!=', ''));
  
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const num = querySnapshot.size;
        setNumDocs(num);  // Atualiza o estado com o número de documentos
  
        // Atualiza para false automaticamente se o número de documentos for menor que 7
        if (num < 7) {
          const docRef = doc(db, 'estudio', eID);
          await updateDoc(docRef, {
            topOn: false
          });
        }
      });
  
      return () => unsubscribe();
    }
  }, [eID]);
  
  
  useEffect(() => {
    if(eID !== null) {
      const db = getFirestore();
      const q = query(collection(db, 'tattoos'), where('EstudioID', '==', eID));
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        // Reinicia todos os estados para garantir que serão vazios caso não sejam encontrados na query
        setStateTop1(null);
        setStateTop2(null);
        setStateTop3(null);
        setStateTop4(null);
        setStateTop5(null);
        setStateTop6(null);
        setStateTop7(null);
  
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const completeData = { ...data, id: doc.id };
  
          // Se top for vazio, o estado correspondente já terá sido definido como null na linha acima
          if (completeData.top !== "") {
            switch(completeData.top) {
              case 1:
                setStateTop1(completeData);
                break;
              case 2:
                setStateTop2(completeData);
                break;
              case 3:
                setStateTop3(completeData);
                break;
              case 4:
                setStateTop4(completeData);
                break;
              case 5:
                setStateTop5(completeData);
                break;
              case 6:
                setStateTop6(completeData);
                break;
              case 7:
                setStateTop7(completeData);
                break;
              default:
                break;
            }
          }
        });
      });
  
      return () => unsubscribe();
    }
  }, [eID]);
  
  

  

  

  

  return (
    <div className="secaotop" >
      <div className="sParte1" style={{ height:'70px'}}>
        <div className="" onClick={toggleConteudoVisivel} style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'8px', marginLeft:'16px'}}>
          <p className="nomeSecao" style={{ fontSize:'22px'}}>
            <FaRankingStar className="iconTop" style={{ marginRight: "8px", color: "#dffc01",  }} /> 
            TOP 7
          </p>
          {isConteudoVisivel ? (
            <BiSolidChevronUp className="iconNS" />
          ) : (
            <BiSolidChevronDown className="iconNS" />
          )}
        </div>
        <div className="sSettings">
          {topOn ? (
            <div className="toggleOnOut active" onClick={toggleTopOn}>
              <p className="toggleOnText">ON</p>
              <div className="toggleInON"></div>
            </div>
          ) : (
            <div className="toggleOffOut active" onClick={toggleTopOn}>
              <div className="toggleInOff"></div>
              <p className="toggleOffText">OFF</p>
            </div>
          )}
        </div>
      </div>
      
      {isConteudoVisivel && (
  <ConteudoTop 
    eID={eID} 
    stateTop1={stateTop1} 
    stateTop2={stateTop2} 
    stateTop3={stateTop3}
    stateTop4={stateTop4}
    stateTop5={stateTop5}
    stateTop6={stateTop6}
    stateTop7={stateTop7}
  />
)}
    </div>
  );
}

export default Top;
