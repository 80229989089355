import React, { useState, useEffect, useRef } from "react";

import { getFirestore, getDocs, query, where, collection } from "firebase/firestore";
import firebase from '../../services/firebaseConfig';
import { FaCircleCheck } from "react-icons/fa6";

function Perso1A({ onNext, onBack, formData, setFormData, nome, perfil, initialCroppedPhotoURL }) {

  const fontPage = {
    roboto: 'fontRoboto', slab: 'fontSlab', serif: 'fontSerif', alegreya: 'fontAlegreya', suse: 'fontSuse', rajdhani: 'fontRajdhani',
    playpen: 'fontPlaypen', josefin:'fontJosefin', playfair:'fontPlayfair', nunito:'fontNunito'
  };

  const colorPage = {
    vermelho:'pagePadrao bVer', laranja:'pagePadrao bLan', amarelo:'pagePadrao bAma', verde:'pagePadrao bVerd', ciano:'pagePadrao bCia',
    azul:'pagePadrao bAzu', roxo:'pagePadrao bRox', rosa:'pagePadrao bRos', magenta:'pagePadrao bMag', branco:'pagePadrao bBra',

    goldVermelho: 'pageVer bAma', goldVerde:'pageVerd bAma', goldAzul:'pageAzu bAma', goldRoxo:'pageRox bAma', goldAmarelo:'pageAma bAma',

    silverVermelho: 'pageVer bBra', silverVerde:'pageVerd bBra', silverAzul:'pageAzu bBra', silverRoxo:'pageRox bBra', silverAmarelo:'pageAma bBra',

    vermelhoVerde:'pageVerd bVer', laranjaCiano:'pageCia bLan', verdeVermelho:'pageVer bVerd', azulAmarelo:'pageAma bAzu',
    
    
  };

  const colorBorder = {
    vermelho:'bVer', laranja:'bLan', amarelo:'bAma', verde:'bVerd', ciano:'bCia',
    azul:'bAzu', roxo:'bRox', rosa:'bRos', magenta:'bMag', branco:'bBra',

    goldVermelho: 'bAma', goldVerde:'bAma', goldAzul:'bAma', goldRoxo:'bAma', goldAmarelo:'bAma',

    silverVermelho: 'bBra', silverVerde:'bBra', silverAzul:'bBra', silverRoxo:'bBra', silverAmarelo:'bBra',

    vermelhoVerde:'bVer', laranjaCiano:'bLan', verdeVermelho:'bVerd', azulAmarelo:'bAzu',
    
    
  };


  
  const handleColorSelect = (colorId) => {
    setFormData({ ...formData, cor: colorId });
  };

  const handleFonteSelect = (font) => {
    setFormData({ ...formData, fonte: font });
  };

  useEffect(() => {
    if (!formData.estilo) { // Verifica se o estilo já está definido
      setFormData(prevState => ({
        ...prevState,
        cor: "vermelho",
        fonte: "roboto" // Define o valor inicial como "estiloN"
      }));
    }
  }, []);

    return(
        <div className='prP'>
          <img style={{ width: "150px"}}
                    src="https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Flogotipo-hKtalus_color_%40150.webp?alt=media&token=0d28d2d1-cd3d-4802-a82d-0bf210f532d2"
                    alt="Ktalus"/>

<span style={{marginTop:'24px' ,fontSize:'24px', fontWeight:'700', color:"#fff"}}>Passo 4 <span style={{ fontWeight:'100', color: '#dffc01'}}>de 5</span></span>
  <span style={{ color:'#dffc01',marginBottom:'8px',fontSize:'14px'}}>Definir a fonte do seu catálogo</span>
  <hr style={{ background:'#dffc01',marginBottom:'24px',}}/>

          <div className="pr--prevCont bgly">
            <div className="pr--prevCont--title">Preview</div>
            <div className={`previewPerfil ${formData.fonte ? fontPage[formData.fonte] || '' : ''} ${formData.cor ? colorPage[formData.cor] || '' : ''}`}>
                <div className='previewPerfilb'></div>
                  <div className={`prepreviewPerfil-borda ${formData.cor ? colorBorder[formData.cor] || '' : ''}`}>
                    <img className="prepreviewPerfil-foto" src={initialCroppedPhotoURL} alt="" background="#fff"/>
                  </div>
                  {formData.nome}
                </div>
                <p className='p--Info'>Você poderá mudar a personalização depois</p>

          </div>
            

<div className='pr--labelsPerso'>
                
        


                <div className="bgly2" style={{ width:'-webkit-fill-available', padding:'16px', borderRadius:'16px'}}>
                <label02>Escolha uma fonte:</label02>
                <div className="fonte">
                  
                    <div onClick={() => handleFonteSelect("roboto")} className={`fonte--bt ${formData.fonte === "roboto" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto"}}>roboto</div>
                    <div onClick={() => handleFonteSelect("slab")} className={`fonte--bt ${formData.fonte === "slab" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto slab"}}>slab</div>
                    <div onClick={() => handleFonteSelect("serif")} className={`fonte--bt ${formData.fonte === "serif" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>serif</div>
                    <div onClick={() => handleFonteSelect("alegreya")} className={`fonte--bt ${formData.fonte === "alegreya" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>alegreya</div>
                    <div onClick={() => handleFonteSelect("rajdhani")} className={`fonte--bt ${formData.fonte === "rajdhani" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>rajdhani</div>
                    <div onClick={() => handleFonteSelect("playpen")} className={`fonte--bt ${formData.fonte === "playpen" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>playpen</div>
                    <div onClick={() => handleFonteSelect("josefin")} className={`fonte--bt ${formData.fonte === "josefin" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>josefin</div>
                    <div onClick={() => handleFonteSelect("playfair")} className={`fonte--bt ${formData.fonte === "playfair" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>playfair</div>
                    <div onClick={() => handleFonteSelect("nunito")} className={`fonte--bt ${formData.fonte === "nunito" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>nunito</div>
                    <div onClick={() => handleFonteSelect("suse")} className={`fonte--bt ${formData.fonte === "suse" ? "ftActive" : ""}`}  style={{fontFamily:"Roboto serif"}}>suse</div>
                  
                </div>
                </div>


                
                


                
                

                 


                    

                    

                
            </div>

            <div className='pr--buttons'>
                <button className='pr--back' onClick={onBack}>Voltar</button>
                <button className='pr--next' onClick={onNext} >Avançar</button>

            </div>
            

        </div>
    )
}


export default Perso1A;