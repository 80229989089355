import './items.css';
import { useState, useEffect, useRef } from 'react';
import { LuFolderEdit } from "react-icons/lu";
import {
    updateDoc,
    getDocs,
    query,
    where,
    getFirestore,
    collection,
    doc,
  } from "firebase/firestore";
  import { db } from '../../../services/firebaseConfig';


function EditSecao({ scID, nome, handleCancelar, setIsSettingsVisible }) {
  const editSecaoRef = useRef(null);
  const [novoNome, setNovoNome] = useState(nome);
  const maxCaracteres = 20; // Defina o limite de caracteres aqui

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editSecaoRef.current && !editSecaoRef.current.contains(event.target)) {
        handleCancelar(); // Fecha o componente quando clicado fora dele
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleCancelar]);

  const handleSalvar = async () => {
    try {
      const sessoesRef = collection(db, "sessoes"); // Referência à coleção "sessoes"
      const q = query(sessoesRef, where("ID_Sessao", "==", scID)); // Consulta para encontrar o documento com o ID correspondente
  
      console.log("Resultado da consulta:", q); 

      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const docRef = doc(db, "sessoes", querySnapshot.docs[0].id); // Referência ao documento encontrado
        await updateDoc(docRef, { nomeSessao: novoNome }); // Atualize o campo "nomeSessao" com o valor do input
      }
      
      console.log("Valor do campo 'nomeSessao' atualizado: ", novoNome);
      setIsSettingsVisible();
      handleCancelar();
    } catch (error) {
      console.error('Erro ao atualizar o nome da sessão:', error);
    }
  };

  return (
    <div className="bgAcao">
      <div className="cardAddSecao" ref={editSecaoRef}>
        <div className="nameAction">
          <LuFolderEdit className="icon"/>
          Editar nome da seção
        </div>

        <div className="contCard">
          <input
            className="input"
            type="text"
            name="novaSecao"
            autoComplete="off"
            value={novoNome}
            onChange={(e) => setNovoNome(e.target.value)}
            maxLength={maxCaracteres} // Limite de 32 caracteres
          />
          {/*<div className="charCounter" style={{ fontSize:'10px'}}>
            {novoNome.length}/{maxCaracteres}
          </div>*/}
          <div className="botoesCard">
            <button className="brCardCancel" onClick={handleCancelar}>
              Cancelar
            </button>
            <button 
              className="btCardConfirm" 
              onClick={handleSalvar} 
              disabled={!novoNome.trim() || novoNome.length > maxCaracteres} // Desativa se estiver vazio ou exceder o limite
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSecao;
